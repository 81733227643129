import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { AuthState } from '@aws-amplify/ui-components';
import { I18n } from '@aws-amplify/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import logoTn from '../../assets/Logos/LogoTn.png';
import Login from '../Login/Login';
import Register from '../Register/Register';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import RegisterConfirm from '../RegisterConfirm/RegisterConfirm';
import packageJson from '../../../package.json';
import useStyles from './signInContainerStyle';
import { Redirect, withRouter } from 'react-router-dom';
import handleAuthUIStateChange from './helpers/handleAuthStateChange';
import checkValidityCookiesTokens from './helpers/checkValidityCookiesTokens';
import { Translations } from "@aws-amplify/ui-components";

const mapStateToProps = (state) => {
	return {
		lang: state.app.lang,
	}
}

const SignInContainer = ({ authState, loaded, ...props }) => {
	const classes = useStyles();
	const [loginError, setLoginError] = React.useState();
	const [redirectToLogin,setRedirectToLogin] = useState(false);

	useEffect(() => {
		setLoginError()
	}, [authState])



	useEffect(()=>{
		const dictLang = {
			[Translations.SIGN_OUT]: "Esci",
		};
		I18n.putVocabulariesForLanguage("it", dictLang);
		I18n.putVocabulariesForLanguage("en", dictLang);
	},[])


	console.log("signInContainer", {authState, loginError})
	return redirectToLogin ? <Redirect to='/dashboard' /> : (
		<div>
			<Grid container className={classes.grid}>
				<Card variant="outlined" className={classes.card}>
					<CardContent className={classes.cardContent}>

						<Grid
							container
							spacing={0}
							direction="column"
							alignItems="center"
							justifyContent="center"
							className={classes.gridLogo}
						>
							<div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 20 }}>
								<span style={{ fontSize: 13 }}>{"Accedi con"}</span>
								<img src={logoTn} style={{ height: 38, maxWidth: 187, marginLeft: 10 }} />
							</div>
						</Grid>

						{
							loginError &&
							<div id="errorLogin" className='customToast'>
								<span>{loginError}</span>
									<button className="customToastClose" onClick={() => setLoginError()}></button>
							</div>
						}

						<div style={{ height: 1, backgroundColor: "#EEECED", width: "100%", marginTop: 10, marginBottom: 40 }}></div>


						<AmplifyAuthenticator usernameAlias="email" initialAuthState={authState} handleAuthStateChange={async(nextAuthState, authData)=>{
							console.log(`~handleAuthStateChange~`,{nextAuthState,authData})
							if (nextAuthState === AuthState.SignedIn && authData?.username) {
								try {
									await checkValidityCookiesTokens()
									handleAuthUIStateChange(nextAuthState);
								} catch (error) {
									console.log(`Token was revoked or invalid`);
								}
								
							}
						}}>
							<ForgotPassword onError={(e) => {
								console.log("callback forgot psw", e)
								setLoginError(JSON.parse(JSON.stringify(e)))
							}} />
							<Login onError={(e) => {
								console.log("callback login", e)
								setLoginError(JSON.parse(JSON.stringify(e)))
							}} />
							<Register />
							<RegisterConfirm />
							<div>
								<p style={{marginBottom:20}}>{I18n.get("LANDING_PAGE_WAIT_REDIRECTION")}</p>
								<AmplifySignOut />
							</div>
						</AmplifyAuthenticator>

						<Typography className={classes.typoToS}>

							<Link
								href="https://www.tecnichenuove.com/privacy/"
								target="_blank"
								color="secondary"
								underline="none"
								className={classes.linkImprint}
							>
								{I18n.get("FOOTER_PRIVACY_POLICY")}
							</Link>
							<span className={classes.midBul}>&bull;</span>
							<Link
								href="https://www.tecnichenuove.com/cookie-policy/"
								target="_blank"
								color="secondary"
								underline="none"
								className={classes.linkTos}
							>
								{I18n.get("FOOTER_COOKIE_POLICY")}
							</Link>


						</Typography>

						<Typography className={classes.packageVersion}>
							{packageJson.version}
						</Typography>
					</CardContent>
				</Card >
			</Grid>
		</div >
	);
}

export default withRouter(connect(mapStateToProps)(SignInContainer));
